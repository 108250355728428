import { Component, Vue } from 'nuxt-property-decorator'
import MachinationsBasePage from './MachinationsBasePage';

@Component({
  name: 'MachinationsLightPage'
})
class MachinationsLightPage extends MachinationsBasePage {

  beforeEnter(to, from, next) {
    this.$vuetify.theme.dark = false;
  }

  pageHead() {
    const pageHead = this.baseHead();

    pageHead.meta.push(
      { hid: 'theme-color', name: 'theme-color', content: '#ffffff' }
    );

    return pageHead;
  }

  head() {
    return this.pageHead();
  }

  beforeRouteLeave(to, from, next) {
    if (from.path.match(/^\/web3/)) {
      this.$vuetify.theme.dark = false;
    }

    if (to.path.match(/^\/web3/)) {
      this.$vuetify.theme.dark = true;
    }

    next();
  }
}

export default MachinationsLightPage;
