
import Vue from 'vue';
import { DIAGRAM_ACTIONS } from '~/domain/constants';
import { TRACKING_ACTION, TRACKING_CATEGORY, TRACKING_TITLE } from '~/domain/constants/TrackingConst';
import { DiagramAction } from '~/domain/interfaces';
import { SegmentEventParams } from '~/domain/repositories/SegmentRepo';
import AppActions from '@/domain/constants/AppActions'
import { getDiagramOpenLink } from '~/domain/helpers'

export default Vue.extend({
  props: {
    iconSize: {
      type: Number,
      default: 24
    },
    diagram: {
      type: Object,
      required: true
    },
  },

  computed: {
    wpData() {
      return this.$store.state.wp;
    },
    user() {
      return this.$store.getters['authenticatedUser']
    },
  },

  methods: {
    toggleLike: function (liked: boolean): void {
      if (
        !this.user || typeof this.user.username === 'undefined' || !this.user.username
      ) {
        this.$store.commit('dialogs/showDialog', {
          dialogName: 'loginDialog',
          show: true,
          sourceAction: 'like this diagram',
          dialogPayload: getDiagramOpenLink(this.diagram?.link)
        })
      } else {
        // this.$store.dispatch()
        this.trackSegmentLikeEvent(liked)
        const diagramAction: DiagramAction = {
          type: liked ? DIAGRAM_ACTIONS.like : DIAGRAM_ACTIONS.unlike,
          fuid: this.diagram.fuid
        }
        this.$store.dispatch('diagrams/updateLikesForCurrentDiagram', { action: diagramAction })
        this.$emit(
          'setTooltipValue',
          this.diagram.likedByAuthenticatedUser ? 'Unlike' : 'Like'
        )
      }
    },
    trackSegmentLikeEvent: function (liked: boolean): void {
      if (this.diagram) {
        if (liked) {
          const segmentData: SegmentEventParams = {
            callback: () => { },
            openInNewTab: false,
            name: TRACKING_TITLE.LIKE,
            action: TRACKING_ACTION.LIKE,
            category: TRACKING_CATEGORY.COMMUNITY,
            label: this.diagram.name,
            misc: [
              { type: 'diagramName', value: this.diagram.name },
              { type: 'diagramFuid', value: this.diagram.fuid },
              { type: 'username', value: this.user ? this.user.username : '' },
            ],
            integrations: ''
          }
          this.$store.dispatch(AppActions.TRACK_SEGMENT_EVENT, { context: this, params: segmentData })
        } else {
          const segmentData: SegmentEventParams = {
            callback: () => { },
            openInNewTab: false,
            name: TRACKING_TITLE.UNLIKE,
            action: TRACKING_ACTION.UNLIKE,
            category: TRACKING_CATEGORY.COMMUNITY,
            label: this.diagram.name,
            misc: [
              { type: 'diagramName', value: this.diagram.name },
              { type: 'diagramFuid', value: this.diagram.fuid },
              { type: 'username', value: this.user ? this.user.username : '' },
            ],
            integrations: ''
          }
          this.$store.dispatch(AppActions.TRACK_SEGMENT_EVENT, { context: this, params: segmentData })
        }
      }
    }
  },
});
