
import Vue, { PropType } from 'vue'
import { Block } from '@interfaces/BlockTypes'

export default Vue.extend({
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },
})
