
import Vue, { PropType } from 'vue'
import { Block } from '~/domain/interfaces/BlockTypes'

export default Vue.extend({
  name: 'AcquisitionFAQ',
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },

  data() {
    return {
      showContent: false,
      isExpanded: false,
      showReadMore: false,
      faqStates: { '0': true },
      hoveringStates: {},
    }
  },

  methods: {
    openNewTab(url: string) {
      window.open(url, '_blank')
    },
    toggleText(faqId: number) {
      this.$set(this.faqStates, faqId, !this.faqStates[faqId])
    },
    updateHoverState(index: number, value: boolean) {
      this.$set(this.hoveringStates, index, value)
    },
  },
})
