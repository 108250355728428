
import { defineComponent } from 'vue'
import MachinationsLightPage from '~/domain/pages/MachinationsLightPage'
import HomepageArticles from '~/components/homepage/HomepageArticles.vue'
import AcquisitionFAQ from '~/components/acquisition/AcquisitionFAQ.vue'
import CommonVideoPlayerDialog from '~/components/common/CommonVideoPlayerDialog.vue'
import AcquisitionCTA from '~/components/acquisition/AcquisitionCTA.vue'
import CommonTags from '~/components/common/CommonTags.vue'
import { getThumbnailUrl } from '@helpers/DiagramHelper'
import ProfileAvatar from '~/components/profile/ProfileAvatar.vue'
import DiagramLikeBtn from '~/components/diagram/DiagramLikeBtn.vue'
import DiagramForkBtn from '~/components/diagram/DiagramForkBtn.vue'
import CommonParagraphsBlock from '~/components/common/CommonParagraphsBlock.vue'
export default defineComponent({
  name: 'CommonCustomDiagramCardViewer',
  components: {
    CommonParagraphsBlock,
    DiagramForkBtn,
    DiagramLikeBtn,
    ProfileAvatar,
    CommonTags,
    AcquisitionCTA,
    CommonVideoPlayerDialog,
    AcquisitionFAQ,
    HomepageArticles,
  },
  extends: MachinationsLightPage,
  layout: 'machinations',
  props: {
    diagrams: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      thumbnailUrl: '',
      observer: null as any,
      autoSwitchTimer: 0 as any,
      featureSelected: 0,
      isPaused: true,
      featureStates: {},
      autoScrollActive: true,
      isDialogOpen: false,
      currentVideoUrl: '',
      trackSegmentEvent: null,
      iconSize: 28,
      btnMinHeight: 42,
      shareProviders: ['facebook', 'twitter', 'linkedin'],
      tooltipLikeText: 'Like',
      aspectRatio: '4 / 3',
      hasHistory: false,
      diagram: {
        name: '',
        fuid: 0,
        owner: {
          name: '',
          username: '',
        },
        link: '',
        forkCount: 0,
        likedByAuthenticatedUser: false,
        lastEdit: '',
      },
    }
  },

  mounted() {},
  computed: {
    isAuthenticated(): boolean {
      return this.$store.getters['userIsAuthenticated']
    },
  },
  methods: {
    sanitizeDiagramName(diagramName: string) {
      return diagramName
        .toLowerCase()
        .replace(/[^a-z\d\s-]+/g, '')
        .replace(/\s+/g, '-')
    },
    getThumbnailUrl,
  },
})
