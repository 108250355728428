
import Vue, { PropType } from 'vue'
import { Block } from '~/domain/interfaces/BlockTypes'
import AppActions from '@constants/AppActions'

export default Vue.extend({
  name: 'AcquisitionCTA',
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },

  data() {
    return {
      isMobile:
        typeof window !== 'undefined' ? window.innerWidth <= 768 : false,
    }
  },
  created() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.checkMobile)
    }
  },
  destroyed() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.checkMobile)
    }
  },
  methods: {
    async ctaButtonClicked() {
      if (this.block?.enableTracking) {
        await this.$store.dispatch(AppActions.TRACK_SIGN_UP_EVENT, {
          context: this,
          location: this.block?.trackingLocation,
          link: this.block?.ctaButton?.link || null,
        })
      }
    },
    checkMobile() {
      if (typeof window !== 'undefined') {
        this.isMobile = window.innerWidth <= 768
      }
    },
  },
})
