var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"homepage-articles",class:{
    [_vm.block?.containerClass]: true,
  },style:({
    backgroundColor: _vm.block?.componentStyle?.backgroundColor,
    paddingTop: _vm.block?.componentStyle?.paddingTop + 'px',
    paddingBottom: _vm.block?.componentStyle?.paddingBottom + 'px',
  })},[_c('v-container',{staticClass:"m-container px-5 px-md-0"},[_c('div',{staticClass:"d-flex flex-wrap flex-column"},[_c('h2',{staticClass:"section__title"},[_vm._v(_vm._s(_vm.block.title))]),_vm._v(" "),_c('div',{staticClass:"articles"},[(_vm.wpPosts && _vm.wpPosts.length)?_c('div',{staticClass:"article"},_vm._l((_vm.wpPosts),function(wpPost){return _c('HomepageArticle',{key:wpPost.ID,attrs:{"post":wpPost}})}),1):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }