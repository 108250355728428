import { render, staticRenderFns } from "./HomepageArticle.vue?vue&type=template&id=59fd42b5&scoped=true"
import script from "./HomepageArticle.vue?vue&type=script&lang=ts"
export * from "./HomepageArticle.vue?vue&type=script&lang=ts"
import style0 from "./HomepageArticle.vue?vue&type=style&index=0&id=59fd42b5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59fd42b5",
  null
  
)

export default component.exports