
import { mapGetters } from 'vuex'
import { Post } from '@/domain/interfaces/PostTypes'
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  data() {
    return {
      showTooltip: false,
      postTitle: null as HTMLElement | null,
    }
  },
  props: {
    post: {
      type: Object as PropType<Post>,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      getCategoryNameById: 'wp/getCategoryNameById',
      getCategorySlugById: 'wp/getCategorySlugById',
      getPostAuthor: 'wp/getPostAuthor',
      getPostAuthorSlug: 'wp/getPostAuthorSlug',
    }),
    readingTime(): number {
      return this.post?.metadata?.[
        '_yoast_wpseo_estimated-reading-time-minutes'
      ]?.[0]
    },
  },
  mounted() {
    this.postTitle = this.$refs.postTitle as HTMLElement
  },
  methods: {
    shouldShowTooltip(element: HTMLElement) {
      const postTitleElement = element
      return postTitleElement.scrollHeight > postTitleElement.clientHeight
    },
  },
})
