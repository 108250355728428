
import { defineComponent, PropType } from 'vue'
import { Block } from '@/domain/interfaces/BlockTypes'
import { Post } from '@interfaces/PostTypes'
export default defineComponent({
  name: 'HomepageArticles',
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
    post: {
      type: Object as PropType<Post>,
      default: null,
    },
  },
  computed: {
    wpPosts(): any {
      return this.$store.state.wp.posts?.slice(0, 3)
    },
  },
})
